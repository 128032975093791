import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Lists`}</em>{` consist of related content grouped together and organized vertically.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`List type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unordered lists`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To present content of equal status or value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ordered lists`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Imply sequence and order, and are commonly used when giving a set of instructions.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h4 {...{
      "id": "length"
    }}>{`Length`}</h4>
    <p>{`Generally, lists should be used to present simple pieces of information. For more complex sets of data, consider using a `}<a parentName="p" {...{
        "href": "/components/data-table"
      }}>{`data table.`}</a></p>
    <h4 {...{
      "id": "order"
    }}>{`Order`}</h4>
    <p>{`Arrange list items in a logical way. For example, if the list is about resource use, the default order might be highest resource use to lowest. Grouping items in categories into smaller, more specific lists might be more meaningful in some contexts. Alternatively, organize in alphabetical or numeric order.`}</p>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p>{`Use list items that are grammatically parallel. For example, do not mix passive voice with active voice or declarative sentences (statements) with imperative sentences (direct command).`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.45652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAAnElEQVQoz6WSzQrDIBCETZpC20NNifjvwbz/O5pZa4qFHKJZ+BgRdhh3ZeygpJRZOedMKfUHVX0+XUKIw/tmo7oJ+gZGfUuDV1dCrXVWY8xEJuABnmDsSum9z5pSouYFrCCCuSuhcy5rjHFEo6sMRW142tRauye9lxkulxazJwwh0Ax9MZ3Bh2bZ/Gws42eMpqFAz7/R+dKmW//iBumeFyjyN3AEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a list",
            "title": "Example of a list",
            "src": "/static/84e9507d75621039f90fb2d208e79948/fb070/list-usage-1.png",
            "srcSet": ["/static/84e9507d75621039f90fb2d208e79948/d6747/list-usage-1.png 288w", "/static/84e9507d75621039f90fb2d208e79948/09548/list-usage-1.png 576w", "/static/84e9507d75621039f90fb2d208e79948/fb070/list-usage-1.png 1152w", "/static/84e9507d75621039f90fb2d208e79948/c3e47/list-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      